.wrapper {
    display: flex;
    flex-wrap: wrap;
    cursor: cell;
    column-gap: 10px;
    row-gap: 10px;
}

.badge {
    padding: 0.5% 1%;
    background-color: white;
    border-radius: 10px;
    color: black;
    transition: all .5s;
}

.badge:hover {
    background-color: hotpink;
    color: black;
}