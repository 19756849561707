.item {
    margin: 10px;
    font-size: 2rem;
}

.link_item {
    text-decoration: none;
    color: black;
}

.wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    background-color: white;
    color: black;
    text-align: center;
    padding-top: 10%;
    transition-property: visibility, opacity;
    transition-duration: .25s;
}