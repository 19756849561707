.nav_item_wrapper {
    height: 80px;
}

.logo_icon_wrapper {
    width: 80px;
    height: 80px;
}

.nav_bar {
    display: flex;
    justify-content: space-between;
    margin-bottom: 10%;
}

.nav_item {
    margin: 10px;
    font-size: 2rem;
}

.nav_item a {
    text-decoration: none;
    color: white
}